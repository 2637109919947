<template>
  <el-row>
    <el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="type_tab">
        <el-tab-pane label="弘新智能边缘计算盒子" name="1">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner2.jpg" alt=""/>
            <div class="title_box">
              <div class="title2">
                <h3>弘新智能边缘计算盒子</h3>
                <p>
                  弘新智能边缘计算设备，基于各大平台芯片，自主研发制造轻量级、可搭载多种主流算法的视频智能分析设备。为客户提供了轻量级、多平台、高算力、高能低耗的边缘计算产品，广泛应用于电力、能源、智慧社区、智慧交通、智能安防等领域行为分析。</p>
              </div>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row :gutter="20" type="flex" align="middle">
                <el-col :span="12">
                  <img class="product" src="../assets/images/product.png" alt=""/>
                </el-col>
                <el-col :span="12">
                  <div class="detail">
                    <p>
                      弘新智能边缘计算设备，基于各大平台芯片，自主研发制造轻量级、可搭载多种主流算法的视频智能分析设备。为客户提供了轻量级、多平台、高算力、高能低耗的边缘计算产品，广泛应用于电力、能源、智慧社区、智慧交通、智能安防等领域行为分析。</p>
                    <p>◆ 支持同时处理多路实时码流或存储码流；</p>
                    <p>◆ 深度学习运算，采用前端图像预处理+后端算法运算结合；</p>
                    <p>◆ 支持人脸检测、跟踪、识别、姿态识别、行为分析等；</p>
                    <p>◆ 低功耗设计，结合外壳散热，可增加散热风扇主动散热，产品稳定可靠；</p>
                    <p>◆ 完全嵌入式结构，高度集成，可支持桌面放置或挂墙安装，安装和维护方便；</p>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mar_b_40">
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon7.png" alt=""/>
                  <p>多平台</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon8.png" alt=""/>
                  <p>多层次算力</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon9.png" alt=""/>
                  <p>高能低耗</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon10.png" alt=""/>
                  <p>应用领域广</p>
                </el-col>
              </el-row>
            </div>
          </el-row>
          <div class="content">
            <div class="jiagou mar_t_40">
              <img src="../assets/images/apply.png" alt=""/>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="可视化融合平台" name="2">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner2.jpg" alt=""/>
            <div class="title_box">
              <div class="title2">
                <h3>可视化融合平台</h3>
                <p>
                  对各种来源视频类资源进行整合，结合人工智能和大数据分析，提供面向业务的视频、人像、车辆等智能分析与数据服务；通过定制开发或集成，为公安、检察、法院、教育、医疗、企业生产等用户提供深入场景的行业应用。</p>
              </div>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <div class="detail mar_t_40 mar_b_40">
                <p>
                  对各种来源视频类资源进行整合，结合人工智能和大数据分析，提供面向业务的视频、人像、车辆等智能分析与数据服务；通过定制开发或集成，为公安、检察、法院、教育、医疗、企业生产等用户提供深入场景的行业应用。</p>
              </div>
              <el-row :gutter="20" type="flex" justify="center" class="mar_b_40">
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon11.png" alt=""/>
                  <p>视频融合</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon12.png" alt=""/>
                  <p>业务融合</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon13.png" alt=""/>
                  <p>数据可视化</p>
                </el-col>
                <el-col :span="6" class="highlights">
                  <img src="../assets/images/highlights_icon14.png" alt=""/>
                  <p>调度指挥</p>
                </el-col>
              </el-row>
            </div>
          </el-row>
          <div class="content">
            <el-row :gutter="20" class="mar_t_40 mar_b_40">
              <el-col :span="11">
                <img class="computer" src="../assets/images/computer.png" alt=""/>
              </el-col>
              <el-col :span="13">
                <el-row type="flex" align="middle" class="event">
                  <div class="event_icon">
                    <div><img src="../assets/images/highlights_icon15.png" alt=""/></div>
                  </div>
                  <div class="event_info">
                    <h3>一个平台</h3>
                    <p>系统深度整合成一个平台。 目的：各系统不是简单的拼凑，而是一个平台、一个入口，统一管理。</p>
                  </div>
                </el-row>
                <el-row type="flex" align="middle" class="event">
                  <div class="event_icon">
                    <div><img src="../assets/images/highlights_icon16.png" alt=""/></div>
                  </div>
                  <div class="event_info">
                    <h3>业务实战化</h3>
                    <p>实现音视频数据资源整合，多维度业务的交叉实战应用。大数据分析统计的辅助决策，为智慧协作、趋势分析等提供客观数据依据。</p>
                  </div>
                </el-row>
                <el-row type="flex" align="middle" class="event">
                  <div class="event_icon">
                    <div><img src="../assets/images/highlights_icon17.png" alt=""/></div>
                  </div>
                  <div class="event_info">
                    <h3>解放人力，及时准确</h3>
                    <p>通过智能化的运维系统，可以大量地减少人工巡检的工作投入， 且系统自动巡检的及时性高、准确性好，提升工作效率。</p>
                  </div>
                </el-row>
                <el-row type="flex" align="middle" class="event">
                  <div class="event_icon">
                    <div><img src="../assets/images/highlights_icon18.png" alt=""/></div>
                  </div>
                  <div class="event_info">
                    <h3>智能分析，助力决策</h3>
                    <p>通过智能化的行为分析、数据比对，可以对工作中不符合规范的行为和紧急事件进行自动筛查和预警，从而提升业务规范化水平和联动响应效率。</p>
                  </div>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center">
              <el-button type="primary" class="online_btn">在线演示</el-button>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="司法智能应用终端" name="3">
          <el-row class="sub_banner">
            <img src="../assets/images/sub_banner2.jpg" alt=""/>
            <div class="title_box">
              <div class="title2">
                <h3>司法智能应用终端</h3>
                <p>
                  智慧法律服务平台涵盖司法局、社会群众、企业、律师、社区等，构筑“线上、线下+公共法律服务平台”全域体系化、资源化、高价值的司法行政服务体系，建设“大整合、高共享、全规范”的“一站式”法律综合服务平台。</p>
              </div>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <div class="detail mar_t_40 mar_b_40">
                <p>
                  智慧法律服务平台，致力于通过应用大数据处理和人工智能互联网+、大数据处理和应用、自然语言识别、机器学习等成熟的信息技术成果，用AI替代法律服务中一切可标准化的工作，协助完成非标准化的工作，改善法律服务的模式和环境，提高工作学习效率，降低法律服务的成本和难度，为广大民众提供高效优质的法律服务。</p>
                <p>
                  智慧法律服务平台涵盖司法局、社会群众、企业、律师、社区等，构筑“线上、线下+公共法律服务平台”全域体系化、资源化、高价值的司法行政服务体系，建设“大整合、高共享、全规范”的“一站式”法律综合服务平台。</p>
              </div>
              <el-row :gutter="20">
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product1.jpg" alt=""/>
                  <h3>公共法律服务管理后台</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product2.jpg" alt=""/>
                  <h3>公共法律服务小程序</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product3.jpg" alt=""/>
                  <h3>桌面智能终端</h3>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mar_b_40">
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product4.jpg" alt=""/>
                  <h3>智能机器人</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product5.jpg" alt=""/>
                  <h3>法律服务立屏</h3>
                </el-col>
                <el-col :span="8" class="scenes">
                  <img src="../assets/images/law_product6.jpg" alt=""/>
                  <h3>无人律所</h3>
                </el-col>
              </el-row>
            </div>
          </el-row>
          <div class="content">
            <el-row class="effect">
              <el-col :span="6">
                <img src="../assets/images/law_icon1.png" alt=""/>
                <p>智能咨询</p>
                <span>提供7×24小时在线免费法律咨询，内置海量法律问答知识库，根据用户提问内容迅速匹配相关法律问题解答。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon2.png" alt=""/>
                <p>在线咨询</p>
                <span>图文咨询、视频咨询。支持语音、文字输入、图片上传，涵盖20个专业领域、优秀律师团在线解答。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon3.png" alt=""/>
                <p>矛盾调解</p>
                <span>线上申请、视频远程调解，支持多方视频，全流程监督留痕。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon4.png" alt=""/>
                <p>法律援助</p>
                <span>搭建群众与法援中心沟通桥梁，畅通群众法律需求渠道。</span>
              </el-col>
            </el-row>
            <el-row class="effect">
              <el-col :span="6">
                <img src="../assets/images/law_icon5.png" alt=""/>
                <p>普法宣传</p>
                <span>海量法治讲座、典型案例、文书模板内容，收录民法典7编、1260条法条内容。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon6.png" alt=""/>
                <p>趣味普法</p>
                <span>通过单人对战、多人对战、团队PK的游戏竞技方式进行法律问题竞答，增加普法的趣味性。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon7.png" alt=""/>
                <p>视频普法</p>
                <span>通过动画短视频方式将民法典发条内容呈现，生动幽默、简单易懂，更好的达到普法目的。</span>
              </el-col>
              <el-col :span="6">
                <img src="../assets/images/law_icon8.png" alt=""/>
                <p>法律服务</p>
                <span>包括视频接访、公证、司法鉴定、行政复议等法律服务。</span>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="产品视频介绍" name="4">
          <el-row class="sub_banner sub_banner_min">
            <img src="../assets/images/sub_banner2.jpg" alt=""/>
            <div class="title_box">
              <div class="title2">
                <h3>产品视频介绍</h3>
                <!--                <p>产品视频介绍</p>-->
              </div>
            </div>
          </el-row>
          <el-row class="gray_bg">
            <div class="content">
              <el-row :gutter="20" class="video_card">
                <el-col :span="8">
                  <el-card shadow="hover">
                    <div class="video_cover" @click="dialogVisible = true">
                      <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                fit="cover"></el-image>
                      <img class="play" src="../assets/images/play.png" alt=""/>
                    </div>
                    <p>产品视频介绍</p>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                    <div class="video_cover" @click="dialogVisible = true">
                      <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                fit="cover"></el-image>
                      <img class="play" src="../assets/images/play.png" alt=""/>
                    </div>
                    <p>产品视频介绍</p>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                    <div class="video_cover" @click="dialogVisible = true">
                      <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                fit="cover"></el-image>
                      <img class="play" src="../assets/images/play.png" alt=""/>
                    </div>
                    <p>产品视频介绍</p>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                    <div class="video_cover" @click="dialogVisible = true">
                      <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                fit="cover"></el-image>
                      <img class="play" src="../assets/images/play.png" alt=""/>
                    </div>
                    <p>产品视频介绍</p>
                  </el-card>
                </el-col>
                <el-col :span="8">
                  <el-card shadow="hover">
                    <div class="video_cover" @click="dialogVisible = true">
                      <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                fit="cover"></el-image>
                      <img class="play" src="../assets/images/play.png" alt=""/>
                    </div>
                    <p>产品视频介绍</p>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <el-dialog title="产品视频介绍" width="680px" :visible.sync="dialogVisible">
      <video width="640" height="360" controls>
        <source
            src="https://dxh.whmxrj.com/jeecg-boot/temp/jcg_1646982369622_1647007495098.mp4">
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  name: "Product",
  data() {
    return {
      activeName: '1',
      dialogVisible: false,
    };
  },
  created() {
    this.activeName = this.$route.params.id
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.product {
  width: 100%;
  height: 360px;
  object-fit: cover;
}

.computer {
  width: 100%;
  height: 420px;
  object-fit: contain;
}

.event {
  margin-bottom: 20px;
}

.event_icon {
  margin-right: 20px;
  width: 80px;
  height: 80px;
  background-color: #4977fc;
  border-radius: 40px;
  border-top-right-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event_icon div {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event_icon img {
  width: 32px;
  height: 32px;
}

.event_info {
  width: calc(100% - 100px);
}

.event h3 {
  margin: 0 0 10px 0;
  color: #3b426b;
}

.event p {
  margin: 0;
  color: #858ab4;
  font-size: 14px;
  line-height: 2;
}

.online_btn {
  background-color: #4977fc;
  border-color: #4977fc;
  padding: 20px 50px;
  font-size: 16px;
}

.scenes img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.scenes h3 {
  margin: 15px 0;
  text-align: center;
  font-size: 22px;
  font-weight: normal;
}

.effect {
  padding: 40px 0 0 0;
}

.effect .el-col-6 {
  padding-right: 40px;
}

.effect .el-col-6 img {
  width: 48px;
  height: 48px;
  padding: 15px;
  border: 2px dotted #a4bbfd;
  border-radius: 50%;
}

.effect .el-col-6 p {
  margin: 15px 0;
  font-size: 22px;
}

.effect .el-col-6 span {
  color: #858ab4;
  font-size: 14px;
  line-height: 2;
}

.video_card {
  padding-bottom: 20px;
}

.video_card .el-col-8 {
  margin-top: 20px;
}

.video_cover {
  width: 100%;
  height: 220px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.video_cover .el-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 99;
}

.video_card .el-col-8 p {
  margin-bottom: 0;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
